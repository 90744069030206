module.exports = {
  NODE:'https://gauss-devnet-web.k8s.clive.tk/api/v1/',
  TUTORIAL_LINK:'https://docs.testnet.imgauss.clive.tk',
  SESSION_STORAGE: {
    currentPair: "current_pair",
    hashedPassword: "hashed_password",
  },
  DEFAULT_TOKEN_PAIR: {
    firstToken: "btc",
    secondToken: "gauss",
  },
  TRANSFER_FEE: 1,
  WITHDRAW_FEE: {
    usdt: 1.14,
  },
  USDT_BASE_NAMES: {
    usdt: "USDT",
    usde: "USDT",
  },
  USDT_NAMES: {},
  MAX_NUMBER_AFTER_COMMA: 3,
  BASE_TOKEN: "GAUSS",
  EXPLORER_URL:'https://gauss-devnet-stat.k8s.clive.tk/#/explorer/',
  WS_SERVER:'wss://gauss-devnet-web.k8s.clive.tk/ws/rate',
  STORAGE_ORDERS_KEY: "orders",
  CLOSED_STORAGE_ORDERS_KEY: "orders-closed",
  STORAGE_TR_KEY: "tr",
  TOKENS_INFO: {
    "0": {
      baseName: "gaus",
      name: "gauss",
      description: "GAUSS",
    },
    "1": {
      baseName: "usdt",
      name: "usdt",
      description: "TETHER",
    },
    "2": {
      baseName: "eth",
      name: "eth",
      description: "ETHEREUM  ",
    },
    "3": {
      baseName: "btc",
      name: "btc",
      description: "BITCOIN",
    },
    "4": {
      baseName: "kube",
      name: "kube",
      description: "DEKUBE",
    },
    "5": {
      baseName: "mega",
      name: "mega",
      description: "MEGADEX",
    },
    "6": {
      baseName: "4090",
      name: "4090",
      description: "Token 4090",
    },
    "7": {
      baseName: "A100",
      name: "A100",
      description: "Token A100",
    },

  },
};
